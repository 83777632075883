import React from 'react';
import styled from 'styled-components';
import qs from 'query-string';
import * as Ant from 'antd';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {useOutlet} from 'reconnect.js';
import * as AppActions from '../../AppActions';
import OrderCustomQuery from './OrderCustomQuery';
import * as CouponUtil from '../../Utils/CouponUtil';
import {Link} from 'gatsby';
import LayaDownloadOrderModal from './LayaDownloadOrderModal';
import {objectIdToDateTime} from '../../Utils/DateUtil';

const oneMonthAgo = new Date();
oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
const defaultQuery = {
  store: '',
  search: '',
  start: oneMonthAgo.toLocaleDateString('sv'),
  end: new Date().toLocaleDateString('sv'),
  price_min: 0,
  price_max: undefined,
  payment_type: undefined,
  payment_status: undefined,
  origin: undefined,
  is_table: undefined,
  limit: 20,
  offset: 0,
};
const collection = 'order';
const projection = {
  _id: 1,
  checkout: 1,
  config: 1,
  created: 1,
  is_uber_direct: 1,
  order_id: 1,
  order_number: 1,
  origin: 1,
  owner: 1,
  payment_resolved: 1,
  payment_status: 1,
  payment_type: 1,
  payment_subtype: 1,
  pick_time: 1,
  status: 1,
  'store._id': 1,
  'store.id': 1,
  'store.name': 1,
  subtotal: 1,
  total: 1,
  updated: 1,
  'scratch_card_event.results.award_type': 1,
  'scratch_card_event.results.award.name': 1,
  'scratch_card_event.results.award._id': 1,
  'scratch_card_event.results.award.created': 1,
  'draw_lots_event.results.award_type': 1,
  'draw_lots_event.results.award.name': 1,
  'draw_lots_event.results.award._id': 1,
  'draw_lots_event.results.award.created': 1,
  'coupon_distribution_event.results.award_type': 1,
  'coupon_distribution_event.results.award.name': 1,
  'coupon_distribution_event.results.award._id': 1,
  'coupon_distribution_event.results.award.created': 1,
  'discount_items.promotion.coupon._id': 1,
  'discount_items.promotion.name': 1,
  'events.results.award_type': 1,
  'events.results.award.id': 1,
  'events.results.award.created': 1,
};

function parseFilters(filters, options) {
  let query = {};
  let {stores} = options;

  if (filters.store) {
    const _stores = stores.filter((s) => s.name.indexOf(filters.store) > -1);
    query['config.store_id'] = {$in: _stores.map((s) => s.id)};
  }

  const start = new Date(
    `${filters.start || defaultQuery.start}T00:00:00+08:00`,
  );
  const end = new Date(`${filters.end || defaultQuery.end}T23:59:59+08:00`);
  query.created = {
    $gte: start.getTime(),
    $lte: end.getTime(),
  };

  if (filters.search) {
    query['$text'] = {$search: filters.search};
  }

  if (filters.price_max || filters.price_min) {
    query.total = {
      $gte: filters.price_min,
      $lte: filters.price_max,
    };
  }

  if (filters.payment_type && filters.payment_type.indexOf('tappay') > -1) {
    let [type, subtype] = filters.payment_type.split('-');
    query.payment_type = type;
    query.payment_subtype = subtype;
  } else {
    query.payment_type = filters.payment_type;
  }

  query.payment_status = filters.payment_status;
  query.origin = filters.origin;
  query['config.table'] =
    filters.is_table === false
      ? {$eq: null}
      : filters.is_table === true
      ? {$ne: null}
      : filters.is_table;
  query['owner'] = filters.owner;

  return query;
}

export default function AdminOrder(props) {
  const params = qs.parse(props.location.search);

  const [user] = useOutlet('user');
  const [stores] = useOutlet('stores');
  const [records, setRecords] = React.useState([]);
  const [filters, setFilters] = React.useState(defaultQuery);
  const [total, setTotal] = React.useState(0);
  const [points, setPoints] = React.useState([]);
  const [stamps, setStamps] = React.useState([]);
  const [download, openDownload] = React.useState(false);

  const columns = React.useMemo(() => {
    return [
      {
        title: '顧客姓名',
        key: 'buyer_name',
        render: (record) =>
          record.origin === 'pos' ? (
            <div>pos</div>
          ) : (
            <div>
              <div>{record.config?.buyer_name}</div>
              <div>{record.config?.buyer_phone}</div>
            </div>
          ),
      },
      {
        title: '訂單編號',
        key: 'order_number',
        render: (record) => (
          <Link to={`/admin/store_order/?id=${record.id}`}>
            {record.order_id}
          </Link>
          // <Ant.Button
          //   type="link"
          //   //href={`/admin/store_order/?id=${record.id}`}
          //   // target="_blank"
          //   style={{padding: 0}}
          //   onClick={(e) => {
          //     e.preventDefault();
          //     AppActions.navigate(`/admin/store_order/?id=${record.id}`);
          //   }}>
          //   {record.order_id}
          // </Ant.Button>
        ),
      },
      {
        title: '分店',
        key: 'store',
        render: (record) => {
          const store = stores.find((s) => s.id === record.config?.store_id);
          return (
            <div>
              <div>{(store && store.name) || record.config?.store_id}</div>
            </div>
          );
        },
      },
      {
        title: '消費金額',
        key: 'total',
        dataIndex: 'total',
      },
      {
        title: '訂單狀態',
        key: 'status',
        dataIndex: 'status',
      },
      {
        title: '付款方式',
        key: 'payment_type',
        render: (record) => (
          <div>
            <div>
              {record.payment_type !== 'tappay'
                ? record.payment_type
                : record.payment_subtype
                ? `tappay-${record.payment_subtype}`
                : 'tappay'}
            </div>
          </div>
        ),
      },
      {
        title: '付款狀態',
        key: 'payment_status',
        dataIndex: 'payment_status',
      },
      {
        title: '訂單成立時間',
        key: 'created',
        render: (record) => {
          return (record.checkout
            ? objectIdToDateTime(record.checkout)
            : new Date(record.created)
          ).toLocaleDateString('sv');
        },
      },
      {
        title: '取餐時間',
        key: 'pick_time',
        dataIndex: 'pick_time',
      },
      {
        title: '取得點數',
        key: 'point',
        dataIndex: 'point',
      },
      {
        title: '取得點數日期',
        key: 'point_created',
        render: (record) =>
          record.point_created
            ? new Date(record.point_created).toLocaleDateString('sv')
            : null,
      },
      {
        title: '取得印花',
        key: 'stamp',
        dataIndex: 'stamp',
      },

      {
        title: '取得印花日期',
        key: 'stamp_created',
        render: (record) =>
          record.stamp_created
            ? new Date(record.stamp_created).toLocaleDateString('sv')
            : null,
      },
      {
        title: '取得優惠券編號',
        key: 'coupon_id',
        render: (record) => {
          if (Array.isArray(record.scratch_card_event?.results)) {
            let scratchItem =
              record.scratch_card_event.results.filter(
                (x) => x && x.award_type === 'COUPON',
              ) || [];
            return scratchItem.map((c) => (
              <div>{CouponUtil.getId(c.award)}</div>
            ));
          } else if (Array.isArray(record.draw_lots_event?.results)) {
            let drawResults =
              record.draw_lots_event.results.filter(
                (x) => x && x.award_type === 'COUPON',
              ) || [];
            return drawResults.map((c) => (
              <div>{CouponUtil.getId(c.award)}</div>
            ));
          } else if (Array.isArray(record.coupon_distribution_event?.results)) {
            let couponDisResult =
              record.coupon_distribution_event.results.filter(
                (x) => x && x.award_type === 'COUPON',
              ) || [];
            return couponDisResult.map((c) => (
              <div>{CouponUtil.getId(c.award)}</div>
            ));
          } else if (Array.isArray(record.events)) {
            return (record.events || []).reduce((sum, event) => {
              sum += (event.results || []).reduce((sum2, result) => {
                sum2 +=
                  (result.award_type === 'COUPON' &&
                    CouponUtil.getId(result.award)) ||
                  '';
                return sum2;
              }, '');
              return sum;
            }, '');
          }

          return null;
        },
      },
      {
        title: '取得優惠券日期',
        key: 'coupon_created',
        render: (record) => {
          if (Array.isArray(record.scratch_card_event?.results)) {
            let scratchItem =
              record.scratch_card_event.results.filter(
                (x) => x && x.award_type === 'COUPON',
              ) || [];
            return scratchItem.map((c) => (
              <div>{new Date(c.award.created).toLocaleDateString('sv')}</div>
            ));
          } else if (Array.isArray(record.draw_lots_event?.results)) {
            let drawResults =
              record.draw_lots_event.results.filter(
                (x) => x && x.award_type === 'COUPON',
              ) || [];
            return drawResults.map((c) => (
              <div>{new Date(c.award.created).toLocaleDateString('sv')}</div>
            ));
          } else if (Array.isArray(record.coupon_distribution_event?.results)) {
            let couponDisResult =
              record.coupon_distribution_event.results.filter(
                (x) => x && x.award_type === 'COUPON',
              ) || [];
            return couponDisResult.map((c) => (
              <div>{new Date(c.award.created).toLocaleDateString('sv')}</div>
            ));
          } else if (Array.isArray(record.events)) {
            return (record.events || []).reduce((sum, event) => {
              sum += (event.results || []).reduce((sum2, result) => {
                sum2 +=
                  (result.award_type === 'COUPON' &&
                    new Date(result.award?.created).toLocaleDateString('sv')) ||
                  '';
                return sum2;
              }, '');
              return sum;
            }, '');
          }
          return null;
        },
      },
      {
        title: '抵用優惠券編號',
        key: 'order_used_coupon_id',
        render: (record) => {
          if (Array.isArray(record.discount_items)) {
            let discount_item = record.discount_items.find(
              (d) => !!d.promotion?.coupon,
            );
            if (discount_item) {
              return CouponUtil.getId(discount_item.promotion.coupon);
            }
          }
          return null;
        },
      },
    ];
  }, [stores]);

  const getRecords = React.useCallback(async () => {
    AppActions.setLoading(true);
    let filters = {...qs.parse(props.location.search)};
    filters.price_min = parseInt(filters.price_min) || 0;
    filters.price_max = parseInt(filters.price_max) || undefined;
    filters.is_table =
      filters.is_table === 'true'
        ? true
        : filters.is_table === 'false'
        ? false
        : undefined;
    filters.limit = parseInt(filters.limit) || 20;
    filters.offset = parseInt(filters.offset) || 0;

    let query = parseFilters(filters, {stores});

    const pagination = {
      limit: filters.limit,
      offset: filters.offset,
    };
    setFilters({...defaultQuery, ...filters});

    try {
      let resp = await JStorage.fetchDocuments(
        collection,
        query,
        null,
        pagination,
        projection,
      );
      setRecords(resp.results);
      setTotal(resp.total);
    } catch (err) {
      console.log(err);
    }
    AppActions.setLoading(false);
  }, [props.location.search]);

  React.useEffect(() => {
    getRecords();
  }, [getRecords]);

  React.useEffect(() => {
    if (records.length > 0 && records?.[0].order_id) {
      (async () => {
        try {
          let points = await JStorage.fetchAllDocuments('user_point', {
            order_id: {$in: records.map((x) => x.order_id)},
          });
          setPoints(points);
        } catch (err) {}
        try {
          let stamps = await JStorage.fetchAllDocuments('stamp_v2', {
            order: {$in: records.map((x) => x.id)},
          });
          setStamps(stamps);
        } catch (err) {}
      })();
    }
  }, [records.length, records?.[0]?.order_id]);

  const onChange = (values) => {
    let queryStr = qs.stringify({...filters, ...values});
    AppActions.navigate('?' + queryStr);
  };

  const orders = React.useMemo(() => {
    let nextOrders = [];
    for (let order of records) {
      let _points = points.filter((x) => x.order_id === order.order_id);
      let point = _points.reduce((sum, p) => {
        sum += p.point;
        return sum;
      }, 0);
      let point_created = _points?.[0]?.created;

      let _stamps = stamps.filter(
        (x) => x.order === order.id && x.status !== 'VOIDED',
      );

      nextOrders = [
        ...nextOrders,
        {
          ...order,
          point: point,
          point_created: point_created,
          stamp: _stamps.length,
          stamp_created: _stamps.length > 0 ? _stamps[0].created : undefined,
        },
      ];
    }
    return nextOrders;
  }, [records, points, stamps]);

  return (
    <Wrapper>
      <h1>訂單</h1>

      <OrderCustomQuery
        value={filters}
        onChange={setFilters}
        onSubmit={() => onChange({offset: 0})}
        onReset={() => onChange({...defaultQuery})}
        openDownload={openDownload}
        onRefresh={() => getRecords()}
      />

      <Ant.Table
        columns={columns}
        dataSource={orders}
        pagination={{
          size: 'small',
          position: ['topRight'],
          total,
          pageSize: filters.limit,
          current: filters.offset / filters.limit + 1,
          showTotal: (total) => `共 ${total} 筆，20筆/頁`,
          showSizeChanger: false,
        }}
        onChange={(pagination) =>
          onChange({offset: (pagination.current - 1) * pagination.pageSize})
        }
        scroll={{x: 'max-content'}}
      />

      <LayaDownloadOrderModal
        visible={download}
        setVisible={openDownload}
        query={parseFilters({...filters, owner: params.owner}, {stores})}
        projection={projection}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;

  & h1 {
    font-size: 32px;
  }

  & h2 {
    margin: 10px 0;
  }
`;
