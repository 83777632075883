import React, {useCallback, useEffect, useState} from 'react';
import {Input, Select, Button} from 'antd';
import styled from 'styled-components';
import Theme from '../../Theme';
import OrderDownloadModal from './LayaDownloadOrderModal';
import qs from 'query-string';

export default function OrderCustomQuery(props) {
  const {value, onChange, onSubmit, onReset, openDownload, onRefresh} = props;

  const setValue = (obj) => onChange((prev) => ({...prev, ...obj}));

  const submit = async (e) => {
    e.preventDefault();

    if (!value.start) {
      return alert('必須選擇起始日期');
    }

    const start = new Date(`${value.start}T00:00:00+08:00`);
    const end = new Date(
      `${value.end || new Date().toLocaleDateString('sv')}T23:59:59+08:00`,
    );

    if (start.getTime() >= end.getTime()) {
      alert('起始日期大於結束日期');
      return;
    }

    if (end - start > 7776000000) {
      // 90 days
      return alert('搜尋日期不可超過90天');
    }

    onSubmit();
  };

  return (
    <Wrapper>
      <h2>進階搜尋</h2>
      <div className="row">
        <div className="label">店名</div>
        <Input
          style={{marginRight: 20, width: 300}}
          value={value.store}
          onChange={(e) => setValue({store: e.target.value})}
        />
        <div className="label">搜尋</div>
        <Input
          style={{width: 300}}
          value={value.search}
          placeholder="訂單編號, 顧客姓名, 顧客電話"
          onChange={(e) => setValue({search: e.target.value})}
        />
      </div>

      <div className="row">
        <div className="label">金額</div>
        <Input.Group style={{marginRight: 20, flex: '0 0 240px'}}>
          <Input
            placeholder="最小"
            style={{width: 100}}
            value={value.price_min}
            onChange={(e) => setValue({price_min: e.target.value})}
          />
          <Input
            placeholder="~"
            style={{width: 30, borderRightWidth: 0, borderLeftWidth: 0}}
            disabled
          />
          <Input
            placeholder="最大"
            style={{width: 100}}
            value={value.price_max}
            onChange={(e) => {
              setValue({price_max: e.target.value});
            }}
          />
        </Input.Group>

        <div className="label">建立時間</div>
        <div className="input-group">
          <input
            type="date"
            value={value.start}
            onChange={(e) => setValue({start: e.target.value})}
          />
          <Input
            placeholder="~"
            style={{width: 30, borderRightWidth: 0, borderLeftWidth: 0}}
            disabled
          />
          <input
            type="date"
            value={value.end}
            onChange={(e) => setValue({end: e.target.value})}
          />
        </div>
      </div>

      <div className="row">
        <div className="label">付款方式</div>
        <Select
          style={{width: 200, marginRight: 20}}
          value={value.payment_type}
          onChange={(value) => setValue({payment_type: value})}
          placeholder="未選取">
          <Select.Option value="cash">cash</Select.Option>
          <Select.Option value="tappay-credit">tappay-credit</Select.Option>
          <Select.Option value="tappay-apple">tappay-apple</Select.Option>
          <Select.Option value="tappay-google">tappay-google</Select.Option>
          <Select.Option value="tappay-express">tappay-express</Select.Option>
          <Select.Option value="linepay">linepay</Select.Option>
          <Select.Option value="jkopay">jkopay</Select.Option>
          <Select.Option value="ubereat">ubereat</Select.Option>
          <Select.Option value="foodpanda">foodpanda</Select.Option>
        </Select>

        <div className="label">付款狀態</div>
        <Select
          style={{marginRight: 20}}
          value={value.payment_status}
          onChange={(value) => setValue({payment_status: value})}
          allowClear={true}
          placeholder="未選取">
          <Select.Option value="pending">pending</Select.Option>
          <Select.Option value="success">success</Select.Option>
          <Select.Option value="failed">failed</Select.Option>
          <Select.Option value="refunded">refunded</Select.Option>
        </Select>

        <div className="label">來源</div>
        <Select
          style={{marginRight: 20}}
          value={value.origin}
          onChange={(value) => setValue({origin: value})}
          placeholder="未選取">
          <Select.Option value="web">web</Select.Option>
          <Select.Option value="app">app</Select.Option>
          <Select.Option value="pos">pos</Select.Option>
          <Select.Option value="ubereat">ubereat</Select.Option>
        </Select>

        <div className="label">桌邊掃碼</div>
        <Select
          style={{marginRight: 20}}
          value={value.is_table}
          onChange={(value) => setValue({is_table: value})}
          placeholder="未選取">
          <Select.Option value={true}>是</Select.Option>
          <Select.Option value={false}>否</Select.Option>
        </Select>
      </div>
      <div
        className="row"
        style={{justifyContent: 'flex-end', marginBottom: 0}}>
        <Button type="primary" onClick={onRefresh} style={{marginRight: 10}}>
          重新整理
        </Button>
        <Button type="primary" onClick={onReset} style={{marginRight: 10}}>
          清空
        </Button>
        <Button
          type="primary"
          onClick={() => openDownload(true)}
          style={{marginRight: 10}}>
          下載
        </Button>
        <Button type="primary" htmlType="submit" onClick={submit}>
          進階搜尋
        </Button>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.form`
  border: solid 1px #ddd;
  padding: 10px;
  border-radius: 8px;

  & > h2 {
    margin-bottom: 10px;
  }
  & > .row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    & .label {
      margin-right: 10px;
      white-space: nowrap;
    }
  }

  & .input-group > input {
    border: solid 1px rgb(217, 217, 217);
    border-radius: 0px;

    &[type='date'] {
      padding: 3px 11px;
    }

    &:first-child {
      border-top-left-radius: ${Theme.radius}px;
      border-bottom-left-radius: ${Theme.radius}px;
    }
    &:last-child {
      border-top-right-radius: ${Theme.radius}px;
      border-bottom-right-radius: ${Theme.radius}px;
    }
  }

  & .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: ${Theme.radius}px;
  }
`;
