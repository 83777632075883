import React from 'react';
import styled from 'styled-components';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {useOutlet} from 'reconnect.js';
import * as Ant from 'antd';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import * as AppActions from '../../AppActions';

export default function OrderDownloadModal(props) {
  const {visible, setVisible, query, projection} = props;
  const [stores] = useOutlet('stores');
  const [loading] = useOutlet('loading');

  const [count, setCount] = React.useState(0);
  const [downloadLink, setDownloadLink] = React.useState(null);

  const {storesMap} = React.useMemo(() => {
    return {
      storesMap: stores.reduce((sum, x) => {
        sum[x.id] = x.name;
        return sum;
      }, {}),
    };
  }, [stores]);

  React.useEffect(() => {
    if (visible) {
      (async () => {
        console.log('download modal query...');

        if (typeof window === 'undefined' || !window.XLSX) {
          console.log('no XLSX');
          return;
        }

        AppActions.setLoading(true);
        let orders = [];
        try {
          orders = await JStorage.fetchAllDocuments(
            'order',
            query,
            null,
            projection,
          );
          setCount(orders.length);

          let points = await JStorage.fetchAllDocuments('user_point', {
            order_id: {$in: [...new Set(orders.map((x) => x.order_id))]},
          });
          let stamps = await JStorage.fetchAllDocuments('stamp', {
            order: {$in: [...new Set(orders.map((x) => x.id))]},
          });

          let nextOrders = [];
          for (let order of orders) {
            let point = points.find((x) => x.order_id === order.order_id);
            let _stamps = stamps.filter((x) => x.order === order.id);

            nextOrders.push({
              ...order,
              point: point?.point,
              point_created: point?.created,
              stamp: _stamps.length,
              stamp_created:
                _stamps.length > 0 ? _stamps[0].created : undefined,
            });
          }
          orders = nextOrders;
        } catch (err) {
          console.warn(err);
          Ant.message.error('資料量過大，請重新篩選');
        }

        if (orders.length > 0) {
          try {
            const wb = window.XLSX.utils.book_new();
            const ws = window.XLSX.utils.aoa_to_sheet([
              [
                '姓名',
                '電話',
                '訂單編號',
                '取餐時間',
                '消費金額',
                '分店',
                '建立時間',
                '付款方式',
                '付款狀態',
                '點數',
                '發放點數時間',
                '印花集章',
                '印花發放時間',
              ],
              ...orders.map((order) => {
                return [
                  order.config.buyer_name,
                  order.config.buyer_phone,
                  order.order_id,
                  order.pick_time,
                  order.total,
                  storesMap[order.config.store_id] || order.config.store_id,
                  new Date(order.created).toLocaleDateString('sv'),
                  order.payment_type !== 'tappay'
                    ? order.payment_type
                    : order.payment_subtype
                    ? `tappay-${order.payment_subtype}`
                    : 'tappay',
                  order.payment_status,
                  order.point || '-',
                  order.point_created
                    ? new Date(order.point_created).toLocaleString('sv')
                    : '-',
                  order.stamp || '-',
                  order.stamp_created
                    ? new Date(order.stamp_created).toLocaleString('sv')
                    : '-',
                ];
              }),
            ]);
            window.XLSX.utils.book_append_sheet(wb, ws, '訂單列表');
            const wbout = window.XLSX.write(wb, {
              bookType: 'xlsx',
              bookSST: false,
              type: 'array',
              cellStyles: true,
              bookImages: true,
            });
            const objUrl = URL.createObjectURL(
              new Blob([wbout], {type: 'application/octet-stream'}),
            );
            setDownloadLink(objUrl);
            await AppActions.delay(1000);
            Ant.message.success('成功創建下載連結');
          } catch (ex) {
            console.warn('ex', ex);
          }
        }

        AppActions.setLoading(false);
      })();
    }
  }, [query, visible]);

  return (
    <Ant.Modal
      title={null}
      footer={null}
      bodyStyle={{padding: 0}}
      width={480}
      visible={visible}
      onOk={() => {
        setVisible(false);
      }}
      onCancel={() => {
        setVisible(false);
      }}
      destroyOnClose={true}>
      <Wrapper>
        <h2>下載訂單</h2>
        {loading.loading ? (
          <Ant.Result icon={<LoadingOutlined />} title="正在載入資料" />
        ) : count > 0 ? (
          <Ant.Result
            status="success"
            title={`找到${count}筆資料`}
            extra={
              downloadLink ? (
                <Ant.Button
                  type="link"
                  href={downloadLink}
                  target="_blank"
                  download="laya-orders.xlsx">
                  點此下載
                </Ant.Button>
              ) : null
            }
          />
        ) : (
          <Ant.Result status="error" title={`找到${count}筆資料`} />
        )}
      </Wrapper>
    </Ant.Modal>
  );
}

const Wrapper = styled.div`
  padding: 20px;
`;
